import { Link } from 'gatsby';
import React from 'react';
import ColoredText from '../../Common/ColoredText';

const Pricing = () => {
    return (
        <section className="pricing-area pt-100 pb-70 ">
            <div className="container">
                <div className="section-title">
                    <h2>Residential Pricing Plans</h2>
                </div>
                <h4 id="pricing-lagos"><ColoredText text="Lagos" /></h4>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix<br/>Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 32,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 8Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix Multi Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 49,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 10Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix MultiPro Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 60,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 15Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix Xtra Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 72,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 20Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>



                <h4 id="pricing-abuja"><ColoredText text="Abuja" /></h4>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix<br/>Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 38,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 8Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix Multi Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 55,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 10Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix MultiPro Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 65,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 15Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>UGoFlix Xtra Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 75,000 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 20Mb/s" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="Unlimited" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                            <div className="btn-box">
                                <Link to="/contact" className="default-btn">
                                    <i className="flaticon-tick"></i>
                                    Select the Plan
                                    <span></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <h4 id="pricing-mobile"><ColoredText text="Ugo Mobile Plans" /></h4>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Home Bronze Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 6,500 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 1.5Mbps" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="9GB" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Home Bronze Excel Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 8,500 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 2Mbps" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="12GB" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>


                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Home Silver Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 12,500 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 2.5Mbps" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="18GB" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>


                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single-pricing-box">
                            <div className="pricing-header">
                                <h3>Home Gold<br/>Plan</h3>
                            </div>

                            <div className="price">
                                <sup>₦</sup> 17,500 <sub>/ Monthly</sub>
                            </div>

                            <ul className="pricing-features">
                                <li><i className='bx bxs-badge-check'></i> Speed <ColoredText text="Up to 2.5Mbps" /></li>
                                <li><i className='bx bxs-badge-check'></i> Data Cap <ColoredText text="27GB" /></li>
                                
                                <li><i className='bx bxs-badge-check'></i> 24/7 Support</li>
                                <li><i className='bx bxs-badge-check'></i> VAT Included</li>
                            </ul>


                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default Pricing